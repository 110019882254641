// CustomerLogin.js
import React from 'react';

const CustomerLogin = () => {
 
	  window.open("https://provui-prod.sarvantanet.com",'_blank') 
};

export default CustomerLogin;

