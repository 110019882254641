/* Header.js */

//<MenuItem onClick={handleNestedMenu4Close}><Link to="/knwoledgecenter">KnowledgeCenter</Link></MenuItem> 
//<li><Link to="/knowledgecenter">KnowledgeCenter</Link></li> 
//<MenuItem onClick={handleNestedMenu2Close}><Link to="/security">Security</Link></MenuItem>
//<li><Link to="/security">Security</Link></li>

import React, { useState , useEffect} from 'react';
import { Box, Center, Flex, useColorMode, useColorModeValue, Image } from '@chakra-ui/react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import logo from '../assets/logo-new.png';
import customer from '../assets/customer.png';
import about from '../pages/About.js'
import contact from '../pages/Contact.js'
import partners from '../pages/Partners.js'
import news from '../pages/News.js'
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Portal from '@mui/material/Portal';

const StyledHeader = styled.header`
  /*background-color: #191970;*/
  background-color: #000503;
  color: #333;
  justify-content: space-between;
  align-items: left;
  padding: 0px 0; 
  overflow: hidden; 

@media screen and (max-width: 768px) {
  /* CSS for mobile view - display menu options */
  .header-items {
    display: none;
  }
  .menu-options {
    display: block;
  }
}

`;
const Nav = styled.nav`
  ul {
    list-style-type: none;
    padding: 0px 0;
    display: flex; 
    justify-content: center;
    font-size: 15px;
  }

  li {
    margin: 0 10px;
  }
  a {
    display: flex;
    color: #FFF; 
    text-decoration: none;
    padding: 10px;
    margin-left: 0px;
  }
ul {
  display: flex;
  margin: 0;
  padding: 0px;
  justify-content: center;
  color: #777 
}
ul li {display: inline-block; margin: 0 10px;}
/*ul li:hover {background: #191970;} */
ul li:hover {background: #000503;} 
ul li:hover ul {display: block;}
ul li ul {
  position: absolute;
  width: 150px;
  display: none;
}
ul li ul li {
  background: #444;
  display: block;
}
ul li ul li a {color: #fff; display:block !important;}
ul li ul li:hover {background: #666;}
`;

const Header = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [selectedElement, setSelectedElement] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

  window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [anchorE3, setAnchorE3] = React.useState(null);
  const [anchorE4, setAnchorE4] = React.useState(null);
  const [nestedAnchorEl, setNestedAnchorEl] = React.useState(null);
  const [nestedAnchorE2, setNestedAnchorE2] = React.useState(null);
  const [nestedAnchorE3, setNestedAnchorE3] = React.useState(null);
  const [nestedAnchorE4, setNestedAnchorE4] = React.useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenu2Click = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleMenu3Click = (event) => {
    setAnchorE3(event.currentTarget);
  };

  const handleMenu4Click = (event) => {
    setAnchorE4(event.currentTarget);
  };

  const handleNestedMenuClick = (event) => {
    setNestedAnchorEl(event.currentTarget);
  };

  const handleNestedMenu2Click = (event) => {
    setNestedAnchorE2(event.currentTarget);
  };

  const handleNestedMenu3Click = (event) => {
    setNestedAnchorE3(event.currentTarget);
  };

  const handleNestedMenu4Click = (event) => {
    setNestedAnchorE4(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenu2Close = () => {
    setAnchorE2(null);
  };

  const handleMenu3Close = () => {
    setAnchorE3(null);
  };

  const handleMenu4Close = () => {
    setAnchorE4(null);
  };

  const handleNestedMenuClose = () => {
    setNestedAnchorEl(null);
    handleMenuClose(); // Close parent menu as well
  };

  const handleNestedMenu2Close = () => {
    setNestedAnchorE2(null);
    handleMenuClose(); // Close parent menu as well
  };

  const handleNestedMenu3Close = () => {
    setNestedAnchorE3(null);
    handleMenuClose(); // Close parent menu as well
  };

  const handleNestedMenu4Close = () => {
    setNestedAnchorE4(null);
    handleMenuClose(); // Close parent menu as well
  };

  return (
 <Box  width='100vw' borderWidth='1px' borderRadius='lg'> 
  {isMobile ? (
    <StyledHeader>
      <Nav>
        <ul>
         <li style={{ listStyle: 'none' ,  marginRight: '100px' }}>
            <Link to="/" style={{ fontSize: '15px' }}><img src={logo} alt="Logo" style={{ width: '175px', height: '60px' ,display: 'flex',justifyContent: "left"}} /></Link>
          </li>
        <li>
        <IconButton
          size="large"
          edge="start"
          color="inherit" 
          aria-label="menu"
          onClick={handleMenuClick}
          >
          <MenuIcon />
        </IconButton>
      <Menu
        id="Main-Menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleNestedMenuClick} style={{ justifyContent: 'space-between'}}>Company <i class="fa fa-angle-down" style={{marginLeft:'5px'}} ></i> </MenuItem>
        <Menu 
          id="Company"
          anchorEl={nestedAnchorEl}
          open={Boolean(nestedAnchorEl)}
          onClose={handleNestedMenuClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          getContentAnchorEl={null}
        >
	  <div style={{ color: '0x000503' }}>
          <MenuItem onClick={handleNestedMenuClose}><Link to="/about" >About</Link></MenuItem>
          <MenuItem onClick={handleNestedMenuClose}><Link to="/partners" >Partners</Link></MenuItem>
          <MenuItem onClick={handleNestedMenuClose}><Link to="/contact">Contact</Link></MenuItem>
          <MenuItem onClick={handleNestedMenuClose}><Link to="/news">News</Link></MenuItem>
	  </div>
        </Menu>
        <MenuItem onClick={handleNestedMenu2Click}>Products <i class="fa fa-angle-down" style={{marginRight:'em'}} ></i> </MenuItem>
        <Menu
          id="Products"
          anchorE2={nestedAnchorE2}
          open={Boolean(nestedAnchorE2)}
          onClose={handleNestedMenu2Close}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          getContentAnchorE2={null}
        >
	  <div style={{ color: '0x000503' }}>
          <MenuItem onClick={handleNestedMenu2Close}><Link to="/naascloud">NaaS Cloud</Link></MenuItem>
          <MenuItem onClick={handleNestedMenu2Close}><Link to="/openhardware">Open HW</Link></MenuItem>
          <MenuItem onClick={handleNestedMenu2Close}><Link to="/nac">NAC</Link></MenuItem>
	  </div>
        </Menu>
        <MenuItem onClick={handleNestedMenu3Click}>Solutions <i class="fa fa-angle-down" style={{marginRight:'em'}} ></i></MenuItem>
        <Menu
          id="Solutions"
          anchorE3={nestedAnchorE3}
          open={Boolean(nestedAnchorE3)}
          onClose={handleNestedMenu3Close}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          getContentAnchorE3={null}
        >
	  <div style={{ color: '0x000503' }}>
          <MenuItem onClick={handleNestedMenu3Close}><Link to="/mdu">MDU</Link></MenuItem>
          <MenuItem onClick={handleNestedMenu3Close}><Link to="/hospitality">Hospitality</Link></MenuItem>
          <MenuItem onClick={handleNestedMenu3Close}><Link to="/education">Education</Link></MenuItem>
          <MenuItem onClick={handleNestedMenu3Close}><Link to="/warehouses">Warehouses</Link></MenuItem>
	  </div>
        </Menu>
        <MenuItem onClick={handleNestedMenu4Click}>Resources <i class="fa fa-angle-down" style={{marginRight:'em'}} ></i></MenuItem>
        <Menu
          id="Resources"
          anchorE4={nestedAnchorE4}
          open={Boolean(nestedAnchorE4)}
          onClose={handleNestedMenuClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          getContentAnchorE4={null}
        >
	  <div style={{ color: '0x000503' }}>
          <MenuItem onClick={handleNestedMenu4Close}><Link to="/blogs">Blogs</Link></MenuItem>
          <MenuItem onClick={handleNestedMenu4Close}><Link to="/whitepapers">WhitePapers</Link></MenuItem>
	  </div>
        </Menu>
	  <div style={{ color: '0x000503' }}>
        <MenuItem onClick={handleNestedMenuClose}><a href="https://provui-prod.sarvantanet.com" target="_blank" rel= "noopener noreferrer" > <img src={customer} alt="Image"/> Customer Login </a> </MenuItem> 
	  </div>
        </Menu>
	  </li>
	  </ul>
	  </Nav>
    </StyledHeader>
  /* Render menu options for mobile view */
  ) : (
    <StyledHeader>
      <Nav >
        <ul>
          <li style={{ listStyle: 'none' ,  marginRight: '200px' }}>
            <Link to="/" style={{ fontSize: '15px' }}><img src={logo} alt="Logo" style={{ width: '175px', height: '60px' ,display: 'flex',justifyContent: "left"}} /></Link>
          </li>
         <li>
           <a href="#" style={{ fontSize: '15px'}}>Company <i class="fa fa-angle-down" style={{marginLeft:'5px'}} ></i></a>
           <ul>
             <li><Link to="/about">About</Link></li>
             <li><Link to="/partners">Partners</Link></li>
             <li><Link to="/contact">Contact</Link></li>
             <li><Link to="/news">News</Link></li>
           </ul>
         </li>
         <li>
           <a href="#" style={{ fontSize: '15px' }}>Products <i class="fa fa-angle-down" style={{marginLeft:'5px'}} ></i></a>
           <ul>
             <li><Link to="/naascloud">NaaS Cloud</Link></li>
             <li><Link to="/openhardware">Open HW</Link></li>
             <li><Link to="/nac">NAC</Link></li>
           </ul>
         </li>
         <li>
           <a href="#" style={{ fontSize: '15px' }}>Solutions <i class="fa fa-angle-down" style={{marginLeft:'5px'}} ></i></a>
           <ul>
             <li><Link to="/mdu">MDU</Link></li>
             <li><Link to="/hospitality">Hospitality</Link></li>
             <li><Link to="/education">Education</Link></li>
             <li><Link to="/warehouses">Warehouses</Link></li>
           </ul>
         </li>
         <li>
           <a href="#" style={{ fontSize: '15px' }}>Resources <i class="fa fa-angle-down" style={{marginLeft:'5px'}} ></i></a>
           <ul>
             <li><Link to="/whitepapers">WhitePapers</Link></li>
             <li><Link to="/blogs">Blogs</Link></li>
           </ul>
         </li>
	   <li><a href="https://provui-prod.sarvantanet.com" target="_blank" rel= "noopener noreferrer" style={{  fontSize: '15px' , marginLeft: '50px'}}> <img src={customer} alt="Image"/> &nbsp; Customer Login &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a></li>
        </ul>
      </Nav>
    </StyledHeader>
      )}
  </Box> 
  );
};

export default Header;
